<template>
  <b-container fluid>
    <div class="about mt-3">
      <h3 class="about-header">OUR STORY</h3>

      <p class="my-story">
        This is the dream of one bus driver who found the need for a special and
        unique way for small groups of people to travel in comfort and in
        privacy in a public vehicle. That is when CANADIAN LUXURY MINIVAN
        CHARTERS was formed. 3 years later, the name was changed to EXECUTIVE
        MINIBUS SERVICES. At present, our largest minibus can carry 14
        passengers only.
      </p>

      <h5 class="my-story">All our drivers are professionally trained in:</h5>
      <ul class="my-story">
        <li>Customer Relations</li>
        <li>Defensive Driving</li>
        <li>First Aid</li>
        <li>C.P.R</li>
        <li>Dress Code</li>
      </ul>
    </div></b-container
  >
</template>

<script>
export default {};
</script>
<style scoped>
.about-header {
  color: red;
  font-weight: bold;
  font-size: 30px;
}
.my-story {
  font-size: 18px;
}
</style>

