<template>
    <div>
      <b-card-group class="card-group">
        <b-card title="Tours and Charters" img-top v-if="isToursAndCharters">
          <img
            class="card-img img-fluid"
            src="../assets/carouselImages/car1.jpg"
          />
  
          <b-card-text class="mt-2">
            At the moment our largest Minibus carries only 14 Passengers, so if
            your group is more than 14 people and you don't mind to travel in
            separate Minibuses. You have found the right company.
            <router-link to="/contactus">
              Click here to submit a request
            </router-link>
          </b-card-text>
        </b-card>
  
        <b-card title="Shuttle Service" img-top v-else-if="isShuttleService">
          <img
            class="card-img img-fluid"
            src="../assets/carouselImages/car2.jpg"
          />
  
          <b-card-text class="mt-2">
            For all your conventions and trade shows, we have enough Minibuses to
            shuttle up to 3,000 people. Do you require a coordinators? (Staff
            members waits at each pick up and drop off location and escort guests
            to the rightful minibus and help with loading and unloading.)
            <router-link to="/contactus">
              Click here to submit a request
            </router-link>
          </b-card-text>
        </b-card>
        <b-card title="Winery Tours" img-top v-else-if="isWineryTours">
          <img
            class="card-img img-fluid"
            src="../assets/servicesImages/winery.jpeg"
          />
  
          <b-card-text class="mt-2">
            There are over hundred of wineries in Ontario and more than 50% of
            them are located in the Niagara Falls region.
            <router-link to="/contactus">
              Click here to submit a request
            </router-link>
          </b-card-text>
        </b-card>
  
        <b-card
          title="Toronto Sightseeing"
          img-top
          v-else-if="isToronoSightSeeing"
        >
          <img
            class="card-img img-fluid"
            src="../assets/servicesImages/toronto.jpg"
          />
  
          <b-card-text class="mt-2">
            <ul>
              <li>Black Creek Pioneer Village</li>
              <li>Montgomery Inn. The Oldest In Canada</li>
              <li>The Old Mill</li>
              <li>High Park</li>
              <li>Exhibition Place, Princes Gate</li>
              <li>Fort York</li>
              <li>Toronto's Oldest None Operational Lighthouse</li>
              <li>The World's Shortest Ferry Ride</li>
              <li>C. N. Tower/Rogers Centre</li>
              <li>China Town</li>
              <li>Casa Loma</li>
            </ul>
          </b-card-text>
          <router-link to="/contactus">
                Click here to submit a request
              </router-link>
        </b-card>
        <b-card title="Airport Transfer" img-top v-else-if="isAirportTransfers">
          <img
            class="card-img img-fluid"
            src="../assets/servicesImages/airport.jpg"
          />
  
          <b-card-text class="mt-2">
            Do you require Airport "Meet & Greet" Service? (Staff member meets you
            at the airport for arrival with a sign and escorts you to your waiting
            transportation.)
            <ul>
              <li>Toronto Pearson International Airport</li>
              <li>London International Airport</li>
              <li>Hamilton Airport</li>
              <li>Buffalo International Airport</li>
              <li>Detroit Metropolitan Airport</li>
            </ul>
            <router-link to="/contactus">
              Click here to submit a request
            </router-link>
          </b-card-text>
        </b-card>
  
        <b-card
          title="Niagara Falls Sightseeing"
          img-top
          v-else-if="isNiagaraFallsSightseeing"
        >
          <img
            class="card-img img-fluid"
            src="../assets/servicesImages/niagara.jpg"
          />
  
          <b-card-text class="mt-2">
            This is NOT a scheduled tour and we do not charge per person, - It is
            by the size of Minibus and strictly private. These are some of the
            suggested must see attractions:
            <ul>
              <li>Marineland - The Falls</li>
              <li>Journey Behind The Falls</li>
              <li>Boat Cruise</li>
              <li>Shopping And Entertainment</li>
              <li>Spanish Aerocar "</li>
              <li>Helicopter Ride Over The Falls</li>
              <li>Niagara On The Lake</li>
              <li>Butterfly Conservatory</li>
            </ul>
          </b-card-text>
          <router-link to="/contactus">
            Click here to submit a request
          </router-link>
        </b-card>
  
        <b-card title="Limousine Service" img-top v-else-if="isLimousineService">
          <img
            class="card-img img-fluid"
            src="../assets/servicesImages/limousine.jpg"
          />
  
          <b-card-text class="mt-2">
            <router-link to="/contactus">
            Click here to submit a request
          </router-link>
          </b-card-text>
        </b-card>
      </b-card-group>
    </div>
  </template>
  
  <script>
  export default {
    name: "SelectedService",
    data() {
      return {
        service_name: this.$route.params.servicename,
      };
    },
    computed: {
      isToursAndCharters() {
        return this.service_name === "toursandcharters";
      },
      isShuttleService() {
        return this.service_name === "shuttleservice";
      },
      isAirportTransfers() {
        return this.service_name === "airporttransfers";
      },
      isToronoSightSeeing() {
        return this.service_name === "torontosightseeing";
      },
      isNiagaraFallsSightseeing() {
        return this.service_name === "niagarafallssightseeing";
      },
      isLimousineService() {
        return this.service_name === "limousineservice";
      },
      isWineryTours() {
        return this.service_name === "winerytours";
      },
    },
  };
  </script>
  
  <style scoped>
  .card-group {
    width: 50%;
    margin: auto;
    margin-top: 10px;
  }
  </style>